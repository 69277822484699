import 'driver.js/dist/driver.css';
import './user-education-tour.css';

import { driver } from 'driver.js';

// if we don't want to manually change versions,
// we could use an MD5 hash of the steps instead (by json stringifying the steps array)
export const TOUR_VERSION = 1;
export const TOUR_STORAGE_KEY = 'user-education-tour';

type DriverOptions = {
  onComplete?: () => void;
  onRender?: () => void;
};

const getDriver = (options?: DriverOptions) => {
  const isMobile = window.innerWidth < 768;
  const driverObj = driver({
    popoverClass: 'driverjs-theme',
    showProgress: false,
    progressText: '({{current}}/{{total}})',
    showButtons: ['next'],
    overlayOpacity: 0,
    popoverOffset: isMobile ? 10 : -150,

    onPopoverRender: popover => {
      options?.onRender?.();
      // create and handle dismiss button on !last popovers
      const dismissButton = document.createElement('button');
      dismissButton.textContent = 'Dismiss';
      popover.footerButtons.prepend(dismissButton);

      dismissButton.addEventListener('click', () => {
        options?.onComplete?.();
        driverObj.destroy();
      });

      // handle progress text inside of next button
      const nextButton = popover.footerButtons.querySelector(
        '.driver-popover-next-btn',
      );

      nextButton &&
        (nextButton.textContent = `Next ${driverObj.getActiveStep()?.popover?.progressText}`);
    },

    steps: [
      {
        element: '#user-education-browse',
        popover: {
          align: 'center',
          title: 'Welcome to the new iHeart site!',
          description: 'Search and discover new content in the Browse tab.',
        },
      },
      {
        element: '#user-education-library',
        popover: {
          align: 'center',
          title: 'Welcome to the new iHeart site!',
          description:
            'Access your followed and created playlists, live stations, artists, and podcasts in the Library tab.',
        },
      },
      {
        element: '#user-education-feedback',
        popover: {
          title: 'Welcome to the new iHeart site!',
          align: 'center',
          description:
            'Help us improve your experience by sharing your feedback before you go.',
          onPopoverRender: popover => {
            driverObj.setConfig({
              popoverOffset: 10,
            });

            popover.footerButtons.style.display = 'grid';
            popover.footerButtons.style.justifyContent = 'center';
            popover.footerButtons.lastChild &&
              (popover.footerButtons.lastChild.textContent = 'Got it!');
          },
          onNextClick: () => {
            options?.onComplete?.();
            driverObj.destroy();
          },
        },
      },
    ],
  });

  return driverObj;
};

export const runUserEducationTour = (options?: DriverOptions) => {
  getDriver(options).drive();
};
